import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { PricingProvider } from './services/pricing-provider.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Survey } from './services/survey-viewModel';

@Injectable()
export class AppConfig {
    private _config: Object;

    constructor(private httpClient: HttpClient, private pricingProvider: PricingProvider) {
    }

    load() {
      return new Promise((resolve, reject) => {
        this.httpClient.get('/assets/config/' + environment.name + '.json')
          .subscribe((data) => {
            this._config = data;
            const pricingsLoader = this.httpClient.get(this.get('calculatorServiceUrl') + '/v1/pricings');
            const surveyLoader = this.httpClient.get<Survey>(this.get('surveyServiceUrl')
              + '/v1/question-sets/' + this.get('surveyQuestionSetId'));

            forkJoin(pricingsLoader, surveyLoader).subscribe((val) => {
                const pricings = val[0];
                this._config['pricings'] = pricings;
                this.pricingProvider.setPricing(pricings);

                this._config['survey'] = val[1];
                resolve(true);
              });
          });
        });
    }

    get(key: any) {
        return this._config[key];
    }
}
