import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { EstimatesListener, CalculatorService } from '../../../../../src/app/services/calculator.service';
import { Estimates } from '../../../../../src/app/services/estimates-viewModel';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatCalendarBody, MatSnackBar } from '@angular/material';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { ParseDataForPDF } from '../../../services/calculator-parse-data-pdf.service';
import { saveAs } from 'file-saver';
import { TdLoadingService } from '@covalent/core';
import { CalculatorModel } from '../../../services/calculator-viewModel';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-calculator-main',
  templateUrl: './calculator-main.component.html',
  styleUrls: ['./calculator-main.component.scss']
})
export class CalculatorMainComponent implements OnInit, EstimatesListener {

  estimates: Estimates;

  selectedTab = 0;
  calcModel: CalculatorModel;

  mpLink: string;
  DEFAULT_MP_LINK = 'https://aws.amazon.com/marketplace/search/results?x=0&y=0&searchTerms=teradata%20intellicloud';

  constructor(private calculatorService: CalculatorService,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private parseDataForPDF: ParseDataForPDF,
    private _loadingService: TdLoadingService,
    private snackBar: MatSnackBar) {
    this.calculatorService.registerListener(this);
    this.calculatorService.calculateBaselineEstimates();
    this.estimates = this.calculatorService.getEstimates();

    const referer = document.referrer;
    const currentLocation = window.location;

    if (referer && referer.indexOf('aws.amazon.com/marketplace/pp/') !== -1) {
      this.mpLink = referer;
    } else {
      this.mpLink = this.DEFAULT_MP_LINK;
    }
  }

  ngOnInit() {
    this.calcModel = this.calculatorService.getCalculatorModel();
  }

  onEstimatesChanged(estimates: Estimates) {
    this.estimates = estimates;
  }

  resetRequested() {
    this.calculatorService.calculateBaselineEstimates();
  }

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  downloadPdf() {
    this._loadingService.register('summaryLoader');
    const createPDFURL = this.appConfig.get('createPDFUrl');

    const parsedData = this.parseDataForPDF.parseDataForPDF(this.calcModel, this.estimates);

    this.httpClient.post(createPDFURL, JSON.stringify(parsedData), {
      responseType: 'text',
    }).pipe(finalize(() => {
      this._loadingService.resolve('summaryLoader');
    })).subscribe((response) => {
      const blob = this.b64toBlob(response, 'application/pdf');
      saveAs(blob, 'estimates.pdf');
    },
    (error) => {
      this.snackBar.open('Something went wrong. Please try again.', null, {duration: 3000});
    });
  }

  resetCalculator() {
    this.calculatorService.resetCalculations();
  }
}
