import { Component, OnInit, Input } from '@angular/core';
import { Estimates } from '../../services/estimates-viewModel';
import { CalculatorService } from '../../services/calculator.service';

@Component({
  selector: 'app-calulator-summary',
  templateUrl: './calulator-summary.component.html',
  styleUrls: ['./calulator-summary.component.scss']
})
export class CalulatorSummaryComponent implements OnInit {

  @Input() estimates: Estimates;

  constructor() { }

  ngOnInit() {}
}
