import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baseline-config-help',
  templateUrl: './baseline-config-help.component.html',
  styleUrls: ['./baseline-config-help.component.scss']
})
export class BaselineConfigHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
