export class ScaleAttributes {
  public days = 0;
  public frequency = 'week';
  public hours = 0;
  public sourceNodeCount = 0;
  public targetNodeCount = 0;
  public mleSourceNodeCount = 0;
  public mleTargetNodeCount = 0;


  constructor(public sourceConfig: string, public targetConfig: string,
    public mleSourceConfig: string, public mleTargentConfig:string) {
    this.reset();
  }

  reset() {
    this.days = 0;
    this.frequency = 'week';
    this.hours = 0;
  }
}
