import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CovalentMessageModule } from '@covalent/core';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CovalentLoadingModule} from '@covalent/core';


import {
  MatButtonModule, MatIconModule, MatFormFieldModule,
  MatInputModule, MatSelectModule, MatRadioModule, MatCheckboxModule, MatSidenavModule, MatDividerModule,
  MatTabsModule, MatTooltipModule, MatCardModule, MatRippleModule, MatSnackBarModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const ANGULAR_MODULES = [
  RouterModule, MatButtonModule, MatIconModule, FormsModule, ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatDividerModule,
  MatTabsModule,
  BrowserAnimationsModule,
  MatDialogModule,
  MatTooltipModule,
  MatCardModule,
  MatRippleModule,
  MatSnackBarModule
];

const COVALENT_MODULES = [
  CovalentLayoutModule, CovalentMessageModule, CovalentLoadingModule
];

const SHARED_MODULES = [
  HttpClientModule,
  BrowserModule
];

@NgModule({
  imports: [
    CommonModule,
    ANGULAR_MODULES,
    COVALENT_MODULES,
    SHARED_MODULES
  ],
  declarations: [],
  exports: [ANGULAR_MODULES, COVALENT_MODULES, SHARED_MODULES]
})
export class SharedModule { }
