import { FormGroup } from '@angular/forms';

export class Utils {
  /*
   *  hack as validator updates validation status after (change)
   */
  static daysValid(form: FormGroup) {
    const frequency = form.controls['frequency'].value;
    const days = form.controls['days'].value;

    switch (frequency) {
      case 'week':
        if (days < 0 || days > 7) {
          return false;
        }
        break;
      case 'month':
        if (days < 0 || days > 30) {
          return false;
        }
        break;
      case 'year':
        if (days < 0 || days > 365) {
          return false;
        }
        break;
    }
    return true;
  }
}
