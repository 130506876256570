import { Injectable ,EventEmitter } from '@angular/core';
import { Estimates } from './estimates-viewModel';
import { CalculatorModel } from './calculator-viewModel';
import { PricingProvider } from './pricing-provider.service';
import { ScaleAttributes } from './scale-attributes';
import { ScalePossibility } from './scale-possibility';
import { Subject } from 'rxjs';


interface PricingKey {
  region: string;
  term: string;
  tier: string;
}

export interface EstimatesListener {
  onEstimatesChanged(estimates: Estimates);
  resetRequested();
}

@Injectable()
export class CalculatorService {

  static STANDARD_CONFIG_NODES = 4;
  static STANDARD_CONFIG_NODE = 'm44xl';

  private listeners: EstimatesListener[] = [];

  private pricing: any;
  private estimates = new Estimates();
  private calculatorModel = new CalculatorModel();
  public onTotalClockHoursUpdate =  new EventEmitter();
  public notifySnackBarDismiss =  new Subject();

  private savedTCoreHoursInStop = 0;
  public isDownSelected = false;
  totalClockHoursByUser = 0;
  totalClockHoursInStop = 0;
  scalePosssibilities= [];

  private regionMap = {
    'north_america': 'north_america',
    'international': 'international',
    'us_west': 'north_america',
    'us_east': 'north_america',
    'eu_frankfurt': 'international'
  };

  private tcoreRatings = {
    'm54xl': 2.96,
    'm58xl':5.14,
    'm512xl': 7.51,
    'm516xl': 10.29,
    'm524xl':15.03,
    'm524xlmle': 4.8
  };

  private clockHours = {
    12: 365 * 24,
    36: 3 * 365 * 24
  };

  constructor(private pricingProvider: PricingProvider) {
    this.pricing = this.pricingProvider.getPricing();
  }

  private getPricing(pricingKey: PricingKey): number {
    const region = this.regionMap[pricingKey.region];
    for (let i = 0; i < this.pricing.length; i++) {
      const element = this.pricing[i];
      if (element.region === region
        && element.term === parseInt(pricingKey.term, 10)
        && element.tier === pricingKey.tier) {
        return element.pricing;
      }
    }
    return -1;
  }

  getEstimates(): Estimates {
    return this.estimates;
  }

  getCalculatorModel(): CalculatorModel {
    return this.calculatorModel;
  }

  private resetEstimates() {
    this.estimates = new Estimates();
    this.notifyListeners();
  }


  private calculateConsumption(tCoreHours, engineType) {
   this.estimates[engineType].daily = Math.round(
      tCoreHours / (365 * parseInt(this.calculatorModel.term, 10) / 12));

    this.estimates[engineType].weekly = Math.round(
      tCoreHours / (52 * parseInt(this.calculatorModel.term, 10) / 12));

    this.estimates[engineType].monthly = Math.round(
      tCoreHours / parseInt(this.calculatorModel.term, 10));

    this.estimates[engineType].yearly = Math.round(
      tCoreHours / (parseInt(this.calculatorModel.term, 10) / 12));
  }

  calculateBaselineEstimates() {
    if (!(this.calculatorModel.term
      && this.calculatorModel.tier
      && this.calculatorModel.sqleNode
      && this.calculatorModel.sqleNodeCount) ||
      (this.calculatorModel.configOption == 'sqlmle' &&
        !this.calculatorModel.mleNodeCount && !this.isDownSelected
      )) {
      this.resetEstimates();
      return;
    }

    const config: any = (this.calculatorModel.mleNode && this.calculatorModel.mleNodeCount) ?
      [this.calculatorModel.sqleNodeCount, this.calculatorModel.sqleNode,
      this.calculatorModel.mleNodeCount, this.calculatorModel.mleNode] :
      [this.calculatorModel.sqleNodeCount, this.calculatorModel.sqleNode];

    const sqleTCoreHours = Math.round(
      (config[0] * this.tcoreRatings[config[1]] * this.clockHours[this.calculatorModel.term])
    );

    this.estimates.slqeTCoreHours = sqleTCoreHours;
    this.calculateConsumption(sqleTCoreHours, 'sqleConsumption');

    if (this.calculatorModel.mleNode && this.calculatorModel.mleNodeCount) {
      const mleTCoreHours = Math.round(
        (config[2] * this.tcoreRatings[config[3]] * this.clockHours[this.calculatorModel.term])
      );
      this.estimates.defaultTCoreHours = sqleTCoreHours + mleTCoreHours;
      this.estimates.mleTCoreHours = mleTCoreHours;
      this.calculateConsumption(mleTCoreHours, 'mleEconsumption');
    } else {
      this.estimates.defaultTCoreHours = sqleTCoreHours;
    }
    this.calculatePrice();
  }

  private calculatePrice() {
    if (!(this.calculatorModel.region && this.calculatorModel.term
      && this.calculatorModel.tier)) {
      return;
    }

    const pricingKey = {
      region: this.calculatorModel.region,
      term: this.calculatorModel.term,
      tier: this.calculatorModel.tier
    };

    this.estimates.totalTCoreHours =
      this.estimates.defaultTCoreHours + this.estimates.addOnTcoreHours + this.estimates.savedTCoreHours;

    const pricing = this.getPricing(pricingKey);
    this.estimates.estimatedPrice = Math.round(
      this.estimates.totalTCoreHours * pricing
    );
    this.notifyListeners();
  }

  private notifyListeners() {
    this.listeners.forEach(listener => listener.onEstimatesChanged(this.estimates));
  }

  registerListener(listener: EstimatesListener) {
    this.listeners.push(listener);
  }

  private recurrenceInTerm(frequency: string): number {
    let recurrence: number;
    switch (frequency) {
      case 'week': {
        recurrence = 52;
        break;
      }
      case 'month': {
        recurrence = 12;
        break;
      }
      case 'year': {
        recurrence = 1;
        break;
      }
    }

    return recurrence * (parseInt(this.calculatorModel.term, 10) / 12);
  }


  calculateClockHours(attribute){
    let clockHours;
    if((attribute.frequency == 'week' && attribute.days == 7 && attribute.hours ==24) ||
    (attribute.frequency == 'month' && attribute.days == 30 && attribute.hours == 24) ){
      clockHours = 365 * attribute.hours;
    }else{
      clockHours = attribute.days * attribute.hours
      * this.recurrenceInTerm(attribute.frequency)
    }
    return clockHours;
  }

  public getTCoresForScaleAttributes(scaleAttributes: ScaleAttributes, useSource = false): number {
    const sourceConfig: any = [scaleAttributes.sourceNodeCount, scaleAttributes.sourceConfig];
    const targetConfig: any = [scaleAttributes.targetNodeCount, scaleAttributes.targetConfig];
    let mleSourceConfig;
    let mleTargetConfig;
    let tcores;

    //let days = this.changeDaysForMaximumHoursInFrequency(scaleAttributes);

    const clockHours = this.calculateClockHours(scaleAttributes);

    if (scaleAttributes.mleSourceNodeCount) {
      mleSourceConfig = [scaleAttributes.mleSourceNodeCount, scaleAttributes.mleSourceConfig];
      mleTargetConfig = [scaleAttributes.mleTargetNodeCount, scaleAttributes.mleTargentConfig];
      let configToUse = sourceConfig;
      let sqleTcores = Math.round(clockHours * configToUse[0] * this.tcoreRatings[configToUse[1]]);
      configToUse = mleSourceConfig;
      let mleTCores = Math.round(clockHours * configToUse[0] * this.tcoreRatings[configToUse[1]]);
      tcores = sqleTcores + mleTCores;
    } else {
      let configToUse = targetConfig;
      if (useSource) {
        configToUse = sourceConfig;
      }
      tcores = Math.round(clockHours * configToUse[0] * this.tcoreRatings[configToUse[1]]);
      if(isNaN(tcores)){
        tcores = 0;
      }
    }
    return tcores;
  }

  getTCoreHoursForScaleAttributes(scaleAttributes: ScaleAttributes) {
    return this.getTCoresForScaleAttributes(scaleAttributes)
      - this.getTCoresForScaleAttributes(scaleAttributes, true);
  }

  addScalePossibilityToEstimates(scalePossibility: ScalePossibility) {
    this.calculatorModel.scalePossibilities.push(scalePossibility);
    this.scalePosssibilities.push(scalePossibility);
    
  }

  removeScalePossibilityToEstimates(scalePossibility: ScalePossibility) {
    const index = this.calculatorModel.scalePossibilities.indexOf(scalePossibility, 0);
    if (index > -1) {
      this.calculatorModel.scalePossibilities.splice(index, 1);
      this.scalePosssibilities.splice(index, 1);
      this.reEstimateUsingModifiedScaleAttributes();
    }
  }

  public reEstimateUsingModifiedScaleAttributes() {
    let totalAddOnTCoreHours = 0;
    let totalSavedTCoresHours = 0;

    this.calculatorModel.scalePossibilities.forEach(possibility => {
      const addOnTCoresHours = this.getTCoresForScaleAttributes(possibility.attributes);
      const savedTCoresHours = this.getTCoresForScaleAttributes(possibility.attributes, true);

      const tCoresForScaleAttrs = (addOnTCoresHours - savedTCoresHours);
      if (tCoresForScaleAttrs > 0) {
        totalAddOnTCoreHours += tCoresForScaleAttrs;
      } else {
        totalSavedTCoresHours += tCoresForScaleAttrs;
      }
    });
    this.estimates.addOnTcoreHours = totalAddOnTCoreHours;
    this.estimates.savedTCoreHours = totalSavedTCoresHours + this.savedTCoreHoursInStop;
    this.estimates.totalTCoreHours = totalAddOnTCoreHours + this.estimates.savedTCoreHours;
    this.notifyListeners();
    this.calculatePrice();
  }

  setStopAttribute(stopAttribute: ScaleAttributes) {
    this.calculatorModel.stopAttribute = stopAttribute;
    this.reEstimateUsingStopAttribute(this.savedTCoreHoursInStop);
  }


  private reEstimateUsingStopAttribute(previouslySaved: number) {
    if (!this.calculatorModel.stopAttribute) {
      this.savedTCoreHoursInStop = 0;
      if (this.isDownSelected) {
        this.estimates.savedTCoreHours -= previouslySaved;
      } else {
        this.estimates.savedTCoreHours = 0;
      }


      // this.estimates.totalTCoreHours = this.estimates.defaultTCoreHours
      //  + this.estimates.addOnTcoreHours + this.estimates.savedTCoreHours;
    } else {
      const attr = this.calculatorModel.stopAttribute;
      const savedTCoresHours = -1 * this.getTCoresForScaleAttributes(attr, true);
      
      
      this.estimates.savedTCoreHours = this.estimates.savedTCoreHours
        - previouslySaved + savedTCoresHours;
      this.savedTCoreHoursInStop = savedTCoresHours;
      this.estimates.savedTCoreHoursInStop = this.savedTCoreHoursInStop;
      this.estimates.totalTCoreHours = this.estimates.addOnTcoreHours + this.estimates.savedTCoreHours;
    }

    this.calculatePrice();
    this.notifyListeners();
  }

  private calculateClockHoursInStop(scaleAttributes:ScaleAttributes){
    const clockHours = scaleAttributes.days * scaleAttributes.hours
    * this.recurrenceInTerm(scaleAttributes.frequency);
    return clockHours;
    
  }

   isSelectedHoursExceeded(stopAttribute){
      this.totalClockHoursByUser = 0;
       this.calculatorModel.scalePossibilities.forEach((possibility)=>{
         if(possibility.checked){
          this.totalClockHoursByUser = this.totalClockHoursByUser + ( possibility.attributes.days *
            possibility.attributes.hours * this.recurrenceInTerm(possibility.attributes.frequency) )
         }     
       })
       if(stopAttribute){
        this.totalClockHoursInStop = 0;
        this.totalClockHoursInStop = this.calculateClockHoursInStop(stopAttribute);
       }    
      if(this.totalClockHoursByUser <= 8760 - this.totalClockHoursInStop){
        this.onTotalClockHoursUpdate.emit('');
         return false;
       }
       return true; 
   }

  public resetCalculations() {
    this.calculatorModel.reset();
    this.estimates.reset();
    this.savedTCoreHoursInStop = 0;
    this.listeners.forEach(listener => listener.resetRequested());
    this.notifyListeners();
  }
}
