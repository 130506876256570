import { Component } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { SVGICONS } from './shared/constants/iconsList';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  svgIcons = SVGICONS;

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    for (const svg of this.svgIcons) {
      this._iconRegistry.addSvgIconInNamespace(
        'assets',
        svg.icon,
        this._domSanitizer.bypassSecurityTrustResourceUrl(svg.address)
      );
    }

    this._iconRegistry.registerFontClassAlias('covalent', 'covalent-icons');
    this._iconRegistry.addSvgIcon('teradata', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata.svg'));
    this._iconRegistry.addSvgIcon('teradata-dark', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata-dark.svg'));
    this._iconRegistry.addSvgIcon('teradata-solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata-solid.svg'));
    this._iconRegistry.addSvgIcon('teradata-icon', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata-icon.svg'));
  }
}
