import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appMaxValue]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValueDirective, multi: true }
  ]
})
export class MaxValueDirective implements Validator, OnChanges {
  @Input()
  frequency: string;

  private _onChange: () => void;

  constructor() {}

  validate(c: AbstractControl): ValidationErrors {
    const value = Number(c.value);
    if ((value !== null || value !== undefined) && this.frequency) {

      if (this.frequency === 'week' && (Number.isNaN(value) || value > 7 || value < 0)) {
        return {
          'maxValue': 'Cannot be greater than 7'
        };
      } else if (this.frequency === 'year' && (Number.isNaN(value) || value > 365 || value < 0)) {
        return {
          'maxValue': 'Cannot be greater than 365'
        };
      } else if (this.frequency === 'month' && (Number.isNaN(value) || value > 30 || value < 0)) {
        return {
          'maxValue': 'Cannot be greater than 30'
        };
      }
    }
    return null;
  }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

  ngOnChanges(changes: SimpleChanges): void {
     if ('frequency' in changes) {
       this.frequency = changes.frequency.currentValue;
       if (this._onChange) {
         this._onChange();
       }
     }
   }
}
