import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route, Routes } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { FaqsMainComponent } from './components/pages/faqs-main/faqs-main.component';
import { CalculatorMainComponent } from './components/pages/calculator-main/calculator-main.component';

const routes: Routes = [
  {
      path: '',
      component: MainComponent,
      children: [
        {
          path: '',
          component: CalculatorMainComponent
        },
        {
          path: 'faqs',
          component: FaqsMainComponent
        }
      ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
