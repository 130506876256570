import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CalculatorService } from '../../services/calculator.service';

@Component({
  selector: 'app-exceeded-snack-bar',
  templateUrl: './exceeded-snack-bar.component.html',
  styleUrls: ['./exceeded-snack-bar.component.scss']
})
export class ExceededSnackBarComponent implements OnInit {

  constructor(private snackBar : MatSnackBar , 
    private calculatorService:CalculatorService) { }

  ngOnInit() {
       this.calculatorService.notifySnackBarDismiss.subscribe(()=>{
          this.dismissSnackBar();
       })
  }

  dismissSnackBar(){
    this.snackBar.dismiss();
  }

}
