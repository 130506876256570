import { Component } from '@angular/core';
import { AppConfig } from '../../app.config';
import { Survey } from '../../services/survey-viewModel';
import { HttpClient } from '@angular/common/http';
import { UserResponse, Response } from '../../services/user-survey-response';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  survey: Survey;
  responses = [];
  questions = {};
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {
    this.survey = appConfig.get('survey');
    this.survey.questions.forEach(element => {
      this.responses.push({
        content: undefined,
        questionId: element.id
      });
      this.questions[element.id] = element;
    });
  }

  private validateQuestionResponse(response, question) {
    if (question.mandatory
      && (response.content === undefined || response.content === null)) {
        return {
          error: true,
          errorMessage: 'Response required'
        };
    }

    if (question.responseConstraints) {
      if (typeof response.content === 'string' &&
        (question.responseConstraints.maxLength
          && question.responseConstraints.maxLength < response.content.length)) {
          return {
            error: true,
            errorMessage: 'only ' + question.responseConstraints.maxLength + ' characters allowed'
          };
      }
    }

    return {
      error: false
    };
  }

  onResponseChanged(index) {
    const questionId = this.responses[index].questionId;
    const validationResult = this.validateQuestionResponse(this.responses[index], this.questions[questionId]);
    if (validationResult.error) {
      this.responses[index].error = validationResult.errorMessage;
    } else {
      this.responses[index].error = undefined;
    }
  }

  submit() {
    const request = new UserResponse();
    request.questionSetId = this.survey.id;

    let hasErrors = false;
    this.responses.forEach((ele, index) => {
      const validationResult = this.validateQuestionResponse(ele, this.questions[ele.questionId]);
      if (validationResult.error) {
          hasErrors = true;
          this.responses[index].error = validationResult.errorMessage;
      }
    });

    const responsesCopy = JSON.parse(JSON.stringify(this.responses));

    const responsesToSend: Response[] = [];
    responsesCopy.forEach((ele, index) => {
      const response = new Response();
      if (ele.content !== undefined && ele.content !== null) {
        response.content = ele.content.toString();
      } else {
        response.content = null;
      }
      response.questionId = ele.questionId;
      responsesToSend.push(response);
    });

    request.responses = responsesToSend;

    return new Promise((resolve, reject) => {
      if (hasErrors) {
        reject({code: 400, error: 'mandatory fields missing'});
      } else {
        this.httpClient.post<UserResponse>(
          this.appConfig.get('surveyServiceUrl') + '/v1/question-sets/' + this.survey.id + '/responses', request)
            .subscribe(resolve, reject);
      }
    });
  }

  textAreaHint(question, response) {
    if (!question.responseConstraints) {
      return;
    }

    if (response.content) {
      return response.content.length + '/' + question.responseConstraints.maxLength;
    }
    return '0/' + question.responseConstraints.maxLength;
  }
}
