import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tier-help',
  templateUrl: './tier-help.component.html',
  styleUrls: ['./tier-help.component.scss']
})
export class TierHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
