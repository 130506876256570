import { SvgIcon } from '../models/svgIcon';

export const SVGICONS: SvgIcon[] = [
    {
        icon: 'teradata',
        address: 'assets/icons/teradata.svg'
    },
    {
        icon: 'node-m4.10xl',
        address: 'assets/icons/m4.10xl.svg'
    },
    {
        icon: 'node-m4.16xl',
        address: 'assets/icons/m4.16xl.svg'
    },
    {
      icon: 'node-m4.4xl',
      address: 'assets/icons/m4.4xl.svg'
    },
    {
      icon: 'aws_marketplace',
      address: 'assets/icons/aws_marketplace.svg'
    },
    {
      icon: 'tcore_bucket',
      address: 'assets/icons/tcore-bucket.svg'
    },
    {
      icon: 'check',
      address: 'assets/icons/check.svg'
    }
];
