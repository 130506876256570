import { Component, OnInit, Output, Input, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {
  @Input()
  starCount: number;
  @Input()
  color: string;
  @Output()
  private ratingUpdated = new EventEmitter<Number>();

  rating = -1;
  ratingArr = [];
  labels = ['Poor', 'Below Average', 'Average', 'Good', 'Excellent'];

  constructor() {
  }


  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
        this.ratingArr[index] = {};
        this.ratingArr[index].star = 'star_border';
        this.ratingArr[index].label = this.labels[index];
    }
  }

  onClick(rating: number) {
    for (let i = 0; i < this.ratingArr.length; i++) {
      if (i < rating) {
        this.ratingArr[i].star = 'star';
      } else {
        this.ratingArr[i].star = 'star_border';
      }
    }
    this.rating = rating;
    this.ratingUpdated.emit(rating);
  }
}
