export class User {
  email: string;
  firstName: string;
  lastName: string;
}

export class Response {
  content: string;
  questionId: string;
}

export class UserResponse {
  questionSetId: string;
  userDetail: User;
  responses: Response[];
  id: string;
}
