import { ConsumptionRate } from './consumptionRate';

export class Estimates {
  defaultTCoreHours = 0;
  slqeTCoreHours = 0;
  mleTCoreHours = 0
  addOnTcoreHours = 0;
  savedTCoreHours = 0;
  totalTCoreHours = 0;
  estimatedPrice = 0;
  savedTCoreHoursInStop = 0;
  sqleConsumption = new ConsumptionRate();
  mleEconsumption = new ConsumptionRate()

  public reset() {
    this.defaultTCoreHours = 0;
    this.slqeTCoreHours = 0;
    this.mleTCoreHours = 0
    this.addOnTcoreHours = 0;
    this.savedTCoreHours = 0;
    this.totalTCoreHours = 0;
    this.estimatedPrice = 0;
    this.sqleConsumption = new ConsumptionRate();
    this.mleEconsumption = new ConsumptionRate();
  }
}
