import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculatorService, EstimatesListener } from '../../services/calculator.service';
import { ScaleAttributes } from '../../services/scale-attributes';
import { DecimalPipe } from '@angular/common';
import { Utils } from '../../shared/utils';
import { CalculatorModel } from '../../services/calculator-viewModel';
import { MatSnackBar } from '@angular/material';
import { ExceededSnackBarComponent } from '../exceeded-snack-bar/exceeded-snack-bar.component';

@Component({
  selector: 'app-stop-scenario',
  templateUrl: './stop-scenario.component.html',
  styleUrls: ['./stop-scenario.component.scss']
})
export class StopScenarioComponent implements OnInit, OnChanges, EstimatesListener {

  @Input() sqleNode: string;
  @Input() term: string;
  @Input() sqleNodeCount: number;
  @Input() mleNode: string;
  @Input() mleNodeCount: string;
  @Input() tier: string;
  @Input() region: string;
  @Input() config: string;
  showVantageSystems = false;
  showFormFields = false;
  vantageSystem;
  exceededClockHours = false;
  calculatorModel : CalculatorModel;

  stopScenario = undefined;
  form: FormGroup;
  stopAttribute: ScaleAttributes;
  estimatedTCores = 0;

  placeholderVisible = false;
  placeholderMessage: string;
  retainRequired = false;

  constructor(private fb: FormBuilder, private calculatorService: CalculatorService, private snackBar: MatSnackBar) {
    this.calculatorService.registerListener(this);

    this.form = this.fb.group({
      'days': [0, Validators.min(0)],
      'frequency': ['week', Validators.required],
      'hours': [24, [Validators.max(24), Validators.min(1)]]
    });

    this.calculatorModel = this.calculatorService.getCalculatorModel();
    if (this.calculatorModel.stopAttribute) {
      this.retainState(this.calculatorModel);
    }
  }

  retainState(model: CalculatorModel) {
    this.stopScenario = model.selectedStopScenario;
    this.stopAttribute = model.stopAttribute;
    this.vantageSystem = model.vantageSystemToStop;
    this.form.controls['days'].setValue(model.stopAttribute.days);
    this.form.controls['hours'].setValue(model.stopAttribute.hours);
    this.form.controls['frequency'].setValue(model.stopAttribute.frequency);
  }

  ngOnInit() {
    this.calculatorService.onTotalClockHoursUpdate.subscribe(() => {
      this.exceededClockHours = false;
      this.openSnackBar();
      this.formFieldsChanged(true);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showVantageSystems = this.config == 'sqlmle' ? true : false;
    this.showFormFields = this.config == 'newSQL' || this.vantageSystem ? true : false;
    if ('config' in changes && changes['config'].currentValue == 'sqlmle') {
      //this.resetStopScenario();
    }
    this.inputsChanged();

  }

  setStopAttribute(sqleNode, sqleNodeCount, sqleConfig, mleNode, mleNodeCount, mleConfig) {
    if (mleNode) {
      this.stopAttribute = new ScaleAttributes(sqleNode, sqleConfig, mleNode, mleConfig);
      this.stopAttribute.mleSourceNodeCount = mleNodeCount;
      this.stopAttribute.mleTargetNodeCount = 0;
    } else {
      this.stopAttribute = new ScaleAttributes(sqleNode, sqleConfig, null, null);
    }
    this.stopAttribute.sourceNodeCount = sqleNodeCount;
    this.stopAttribute.targetNodeCount = 0;
  }


  inputsChanged() {
    if ((!this.config || !this.term || !this.tier || !this.region) && this.stopScenario) {
      this.placeholderMessage = 'Please select the region, license tier, and the baseline configuration';
      this.placeholderVisible = true;
      this.resetStopScenario();
    } else if (this.config === 'newSQL' && (!this.sqleNode || !this.sqleNodeCount)) {
      this.placeholderMessage = 'Please select the region, license tier, and the baseline configuration';
      this.placeholderVisible = true;
      this.resetStopScenario();
    } else if (this.config === 'sqlmle' && (!this.sqleNode || !this.sqleNodeCount || !this.mleNode || !this.mleNodeCount)) {
      this.placeholderMessage = 'Please select the region, license tier, and the baseline configuration';
      this.placeholderVisible = true;
      this.resetStopScenario();
    } else if (this.config && this.term && this.region && this.tier) {
      this.placeholderVisible = false;

      if (this.stopScenario) {
        // using placeholder target config
        this.formFieldsChanged(false);
      } else {
        // using placeholder source, target config
        this.setStopAttribute('m44xl', 0, 'm44xl', null, null, null);
        this.resetStopScenario();
      }
    } else {
      this.placeholderVisible = false;
    }
  }

  onVantageSystemSelection() {
    this.showFormFields = true;
    this.calculatorModel.vantageSystemToStop = this.vantageSystem;
    this.inputsChanged();
  }

  stopScenarioSelectionChanged() {
    this.calculatorModel.selectedStopScenario = this.stopScenario;
    this.inputsChanged();
  }

  daysValid() {
    return Utils.daysValid(this.form);
  }

  private formValid(form: FormGroup) {
    return (Utils.daysValid(form)
      && form.controls['hours'].valid
      && form.controls['frequency'].valid);
  }

  private copyFormFieldToModel() {
    this.stopAttribute.days = this.form.controls['days'].value;
    this.stopAttribute.frequency = this.form.controls['frequency'].value;
    this.stopAttribute.hours = this.form.controls['hours'].value;
  }

  formFieldsChanged(calledFromExceeded) {
    let estimatedTCores;

    if (!this.formValid(this.form)) {
      this.stopAttribute.days = 0;
    } else if (this.stopAttribute) {
      this.copyFormFieldToModel();
    }
    if (!calledFromExceeded && this.calculatorService.isSelectedHoursExceeded(this.stopAttribute)) {
      this.exceededClockHours = true;
      this.openSnackBar();
    } else if(this.formValid(this.form)) {
      this.exceededClockHours = false;
      this.openSnackBar();
      if (this.config == 'newSQL') {
        this.setStopAttribute(this.sqleNode, this.sqleNodeCount, 'm44xl', null, null, null);
        this.copyFormFieldToModel();
        estimatedTCores =
          this.calculatorService.getTCoresForScaleAttributes(this.stopAttribute, true);
      } else if (this.vantageSystem == 'sqlmle') {
        this.setStopAttribute(this.sqleNode, this.sqleNodeCount, 'm44xl', this.mleNode, this.mleNodeCount, 'm524xlmle');
        this.copyFormFieldToModel();
        estimatedTCores = this.calculatorService.getTCoresForScaleAttributes(this.stopAttribute, true);
      } else {
        this.setStopAttribute(this.mleNode, this.mleNodeCount, 'm524xlmle', null, null, null);
        this.copyFormFieldToModel();

        estimatedTCores = this.calculatorService.getTCoresForScaleAttributes(this.stopAttribute, true);
      }

      this.estimatedTCores = estimatedTCores;
      this.calculatorService.setStopAttribute(this.stopAttribute);
    }


  }



  getEstimatedTCoreHours(): string {
    if (this.estimatedTCores === 0) {
      return '0';
    } else {
      let tCoreHours = this.estimatedTCores;
      if (this.estimatedTCores < 0) {
        tCoreHours = -1 * this.estimatedTCores;
      }
      return new DecimalPipe('en-US').transform(tCoreHours);
    }
  }

  openSnackBar() {
    if (this.exceededClockHours) {
      //in stop scenario the snack bar opens twice to avoid that the below condition is used
      if (!this.snackBar._openedSnackBarRef)
        this.snackBar.openFromComponent(ExceededSnackBarComponent);
    } else {
      this.calculatorService.notifySnackBarDismiss.next();
    }
  }


  resetStopScenario() {
    this.estimatedTCores = 0;
    if (this.stopAttribute) {
      this.stopAttribute.frequency = 'week';
      this.stopAttribute.days = 0;
      this.stopAttribute.hours = 0;

      this.form.controls['days'].setValue(0);
      this.form.controls['hours'].setValue(24);
      this.form.controls['frequency'].setValue('week');

      this.calculatorService.setStopAttribute(null);
     
    }
    if (this.stopScenario!=undefined && !this.calculatorService.isSelectedHoursExceeded(this.stopAttribute)) {
      this.exceededClockHours = false;
      this.openSnackBar();
    }
    
    this.calculatorService.totalClockHoursInStop = 0;
  }

  onEstimatesChanged() { }

  resetRequested() {
    this.resetStopScenario();
    this.stopScenario = undefined;
  }

  maxValueForDays() {
    if (this.stopAttribute.frequency === 'week') {
      return 7;
    } else if (this.stopAttribute.frequency === 'month') {
      return 30;
    } else if (this.stopAttribute.frequency === 'year') {
      return 365;
    }
  }
}
