import { Injectable } from '@angular/core';

@Injectable()
export class PricingProvider {
  pricing: any;
  constructor() { }

  setPricing(pricing) {
    this.pricing = pricing;
  }

  getPricing() {
    return this.pricing;
  }
}
