import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs-main',
  templateUrl: './faqs-main.component.html',
  styleUrls: ['./faqs-main.component.scss']
})
export class FaqsMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
