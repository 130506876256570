import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FeedbackComponent } from '../feedback/feedback.component';
import { TdLoadingService } from '@covalent/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  route: string;

  @ViewChild('feedback')
  feedback: any;

  @ViewChild('feedbackComponent')
  feedbackComponent: FeedbackComponent;
  showFeedbackForm = true;
  showFeedbackSubmissionSuccess = false;

  constructor(location: Location, router: Router, private dialog: MatDialog,
    private _loadingService: TdLoadingService, public snackBar: MatSnackBar) {
    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
      } else {
        this.route = '';
      }
    });
  }

  ngOnInit() { }

  openFeedback() {
    this.dialog.open(this.feedback);
  }

  submitFeedback() {
    this._loadingService.register('feedbackSubmitLoader');
    this.feedbackComponent.submit().then(feedback => {
      this.showFeedbackSubmissionSuccess = true;
      this.showFeedbackForm = false;
      this._loadingService.resolve('feedbackSubmitLoader');
    }).catch(err => {
      this.showFeedbackForm = true;
      this.showFeedbackSubmissionSuccess = false;
      this._loadingService.resolve('feedbackSubmitLoader');
      if (err instanceof HttpErrorResponse) {
        this.snackBar.open('Something went wrong. Please try submitting the form again.', null, {duration: 3000});
      }
    });
  }

  feedbackSuccessClose() {
    this.showFeedbackForm = true;
    this.showFeedbackSubmissionSuccess = false;
    this.dialog.closeAll();
  }
}
