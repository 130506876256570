
export class ParseDataForPDF {
  calcModel;
  estimates;
  parsedData = {
    term: 0,
    tier: '',
    configuration: '',
    region: '',
    scaleup: [],
    scaledown: [],
    scaleout: [],
    stop: [],
    defaultTcoreHours: 0,
    estimatedPrice: 0,
    totalTcoreHours: 0,
    minTCoreHours: '',
    month: ''
  };

  configObj = {
    m44xl: 'small',
    m410xl: 'medium',
    m416xl: 'large'
  };

  initializePDF() {
    this.parsedData = {
      term: 0,
      tier: '',
      configuration: '',
      region: '',
      scaleup: [],
      scaledown: [],
      scaleout: [],
      stop: [],
      defaultTcoreHours: 0,
      estimatedPrice: 0,
      totalTcoreHours: 0,
      minTCoreHours: '',
      month: ''
    };

  }

  constructor() {
    this.initializePDF();
  }

  setServiceVar(calcModelTemp, estimatesTemp) {
    this.calcModel = calcModelTemp;
    this.estimates = estimatesTemp;
  }

  calculateScalingRequirment(sourceNodeCount, sourceConfig, targetNodeCount, targetConfig, days, frequency, hours, isStop) {
    let scaleConfigObj;
    if (!isStop) {
      scaleConfigObj = sourceNodeCount + ' Nodes, ' + this.configObj[sourceConfig] +
        ' to ' + targetNodeCount + ' Nodes, ' + this.configObj[targetConfig] +
        '(' + days + ' day(s) per ' + frequency + ' & ' + hours + ' hour(s) per day)';
    } else {
      scaleConfigObj = days + ' day(s) per ' + frequency + ' & ' + hours + ' hour(s) per day';
    }

    return scaleConfigObj;
  }

  setStopAttribute = () => {
    const tempStopObj = {
      estimatedTCoreHours: 0,
      stopConfig: ''
    };
    tempStopObj.stopConfig = this.calculateScalingRequirment(this.calcModel.stopAttribute.sourceNodeCount,
      this.calcModel.stopAttribute.sourceConfig, this.calcModel.stopAttribute.targetNodeCount,
      this.calcModel.stopAttribute.targetConfig, this.calcModel.stopAttribute.days,
      this.calcModel.stopAttribute.frequency, this.calcModel.stopAttribute.hours, true);

    tempStopObj.estimatedTCoreHours = this.estimates.savedTCoreHoursInStop;

    this.parsedData.stop.push(tempStopObj);
  }


  setScaleUpAttribute = (sp) => {
    const tempScaleUpObj = {
      estimatedTCoreHours: 0,
      scaleUpConfig: ''
    };

    tempScaleUpObj.scaleUpConfig = this.calculateScalingRequirment(sp.attributes.sourceNodeCount,
      sp.attributes.sourceConfig, sp.attributes.targetNodeCount,
      sp.attributes.targetConfig, sp.attributes.days, sp.attributes.frequency, sp.attributes.hours, false);

    tempScaleUpObj.estimatedTCoreHours = sp.estimatedTCores;

    this.parsedData.scaleup.push(tempScaleUpObj);

  }

  setScaleDownAttribute = (sp) => {
    const tempScaleDownObj = {
      scaleDownConfig: '',
      estimatedTCoreHours: 0
    };
    tempScaleDownObj.scaleDownConfig = this.calculateScalingRequirment(sp.attributes.sourceNodeCount,
      sp.attributes.sourceConfig, sp.attributes.targetNodeCount,
      sp.attributes.targetConfig, sp.attributes.days, sp.attributes.frequency, sp.attributes.hours, false);

    tempScaleDownObj.estimatedTCoreHours = sp.estimatedTCores;

    this.parsedData.scaledown.push(tempScaleDownObj);
  }

  setScaleOutAttribute = (sp) => {
    const tempScalOutObj = {
      estimatedTCoreHours: 0,
      scaleOutConfig: ''
    };
    tempScalOutObj.scaleOutConfig = this.calculateScalingRequirment(sp.attributes.sourceNodeCount,
      sp.attributes.sourceConfig, sp.attributes.targetNodeCount,
      sp.attributes.targetConfig, sp.attributes.days, sp.attributes.frequency, sp.attributes.hours, false);

    tempScalOutObj.estimatedTCoreHours = sp.estimatedTCores;

    this.parsedData.scaleout.push(tempScalOutObj);
  }

  setNoneIfNoScalingRequirment = () => {
    if (!this.parsedData.scaleup.length) {
      this.parsedData.scaleup.push({
        estimatedTCoreHours: '0',
        scaleUpConfig: 'none'
      });
    }
    if (!this.parsedData.scaledown.length) {
      this.parsedData.scaledown.push({
        estimatedTCoreHours: '0',
        scaleDownConfig: 'none'
      });
    }
    if (!this.parsedData.scaleout.length) {
      this.parsedData.scaleout.push({
        estimatedTCoreHours: '0',
        scaleOutConfig: 'none'
      });
    }
    if (!this.parsedData.stop.length) {
      this.parsedData.stop.push({
        estimatedTCoreHours: '0',
        stopConfig: 'none'
      });
    }

  }


  parseDataForPDF = (calcModel, estimates) => {
    this.setServiceVar(calcModel, estimates);
    this.initializePDF();
    this.parsedData.term = (this.calcModel.term / 12);
    this.parsedData.tier = this.calcModel.tier;

    if (this.calcModel.configOption === 'standard') {
      this.parsedData.configuration = '4-Node small(m4.4xl)';
    } else {
      this.parsedData.configuration = this.calcModel.nodeCount + '-Node ' +
        this.configObj[this.calcModel.node] + '(' + this.calcModel.node + ')';
    }

    this.parsedData.region = this.calcModel.region;
    this.calcModel.scalePossibilities.forEach((sp) => {
      if (sp.scenario === 'up') {
        this.setScaleUpAttribute(sp);

      } else if (sp.scenario === 'down') {
        this.setScaleDownAttribute(sp);

      } else {
        this.setScaleOutAttribute(sp);
      }
    });

    if (this.calcModel.stopAttribute) {
      this.setStopAttribute();
    }
    this.setNoneIfNoScalingRequirment();

    this.parsedData.defaultTcoreHours = this.estimates.defaultTCoreHours;
    this.parsedData.estimatedPrice = this.estimates.estimatedPrice;
    this.parsedData.totalTcoreHours = this.estimates.totalTCoreHours;
    this.parsedData.minTCoreHours = '50,000'; // TODO change
    this.parsedData.month = 'January'; // TODO change
    console.log(this.parsedData);
    return this.parsedData;
  }

}
