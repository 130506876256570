import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './components/main/main.component';
import { CalulatorComponent } from './components/calulator/calulator.component';
import { CalulatorSummaryComponent } from './components/calulator-summary/calulator-summary.component';
import { CalulatorFaqsComponent } from './components/calulator-faqs/calulator-faqs.component';
import { TierHelpComponent } from './components/tier-help/tier-help.component';
import { BaselineConfigHelpComponent } from './components/baseline-config-help/baseline-config-help.component';
import { CalculatorService } from './services/calculator.service';
import { PricingProvider } from './services/pricing-provider.service';
import { ScaleScenarioComponent } from './components/scale-scenario/scale-scenario.component';
import { StopScenarioComponent } from './components/stop-scenario/stop-scenario.component';
import { MaxValueDirective } from './directives/max-value.directive';
import { CalculatorMainComponent } from './components/pages/calculator-main/calculator-main.component';
import { FaqsMainComponent } from './components/pages/faqs-main/faqs-main.component';
import { NumericDirective } from './directives/numeric.directive';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { StarRatingComponent } from './components/feedback/star-rating/star-rating.component';
import { ParseDataForPDF } from './services/calculator-parse-data-pdf.service';
import { ExceededSnackBarComponent } from './components/exceeded-snack-bar/exceeded-snack-bar.component';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CalulatorComponent,
    CalulatorSummaryComponent,
    CalulatorFaqsComponent,
    TierHelpComponent,
    BaselineConfigHelpComponent,
    ScaleScenarioComponent,
    StopScenarioComponent,
    MaxValueDirective,
    CalculatorMainComponent,
    FaqsMainComponent,
    NumericDirective,
    FeedbackComponent,
    StarRatingComponent,
    ExceededSnackBarComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule
  ],
  entryComponents:[ExceededSnackBarComponent],

  providers: [
    AppConfig,
    {
        provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig],
        multi: true
    },
    CalculatorService,
    PricingProvider,
    ParseDataForPDF

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
