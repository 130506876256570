import { ScaleAttributes } from './scale-attributes';
import { ScalePossibility } from './scale-possibility';

export class CalculatorModel {
  region: string;
  term = '12';
  tier = undefined;
  node = undefined;
  nodeCount = undefined;

  sqleNode = undefined;
  sqleNodeCount = undefined;
  mleNode = undefined;
  mleNodeCount= undefined;
  vantageSystemToStop = undefined;
  selectedStopScenario = undefined;

  configOption = undefined;

  scalePossibilities: ScalePossibility[] = [];
  stopAttribute: ScaleAttributes;

  public reset() {
    this.region = undefined;
    this.term = '12';
    this.tier = undefined;
    this.node = undefined;
    this.nodeCount = undefined;
    this.sqleNode = undefined;
    this.sqleNodeCount = undefined;
    this.mleNode = undefined;
    this.mleNodeCount = undefined;
    this.configOption = undefined;

    this.scalePossibilities = [];
    this.stopAttribute = undefined;
  }
}
