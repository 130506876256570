import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { CalculatorModel } from '../../services/calculator-viewModel';
import { MatDialog } from '@angular/material';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { CalculatorService, EstimatesListener } from '../../services/calculator.service';
import { Estimates } from '../../services/estimates-viewModel';
import { ConsumptionRate } from '../../services/consumptionRate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

const defaultTCoreHoursReveal = trigger('defaultTCoreHoursState', [
  state('hidden', style({
    display: 'none'
  })),
  state('visible', style({
    display: 'block'
  })),
  transition('hidden => visible', [
    style({ height: 0, opacity: 0 }),
    animate(250, style({ height: '*' }))
  ]),
  transition('visible => hidden', [
    style({ height: '*', opacity: 0 }),
    animate(250, style({ height: 0 }))
  ])
]);

const termMessageReveal = trigger('termMessageState', [
  state('hidden', style({
    display: 'none'
  })),
  state('visible', style({
    display: 'block'
  })),
  transition('hidden => visible', [
    style({ height: 0, opacity: 0 }),
    animate(250, style({ height: '*' }))
  ]),
  transition('visible => hidden', [
    style({ height: '*', opacity: 0 }),
    animate(250, style({ height: 0 }))
  ])
]);

@Component({
  selector: 'app-calulator',
  templateUrl: './calulator.component.html',
  styleUrls: ['./calulator.component.scss'],
  animations: [defaultTCoreHoursReveal, termMessageReveal]
})
export class CalulatorComponent implements EstimatesListener, OnInit {

  defaultTCoreHoursState = 'hidden';
  termMessageState = 'hidden';
  tierSelected = false;
  selectedMleNode = 'm524xlmle';

  calculatorModel: CalculatorModel;
  estimates: Estimates;
  isMobile = false;

  @ViewChild('regionsHelp')
  regionsHelp: any;
  @ViewChild('scaleOutHelp')
  scaleOutHelp: any;
  @ViewChild('scaleUpHelp')
  scaleUpHelp: any;
  @ViewChild('scaleDownHelp')
  scaleDownHelp: any;
  @ViewChild('stopHelp')
  stopHelp: any;
  @ViewChild('baselineConfigHelp')
  baselineConfigHelpModal: any;
  @ViewChild('baselineTCoresHelp')
  baselineTCoresHelp: any;
  @ViewChild('tierHelp')
  tierHelpModel: any;

  sqleConsumptionRate: ConsumptionRate;
  mleConsumptionRate: ConsumptionRate;

  customConfigForm: FormGroup;
  sqleConfigForm: FormGroup;
  mleConfigForm: FormGroup;
  instances = [{ label: 'm5.4xlarge', type: 'm54xl' },
  { label: 'm5.8xlarge', type: 'm58xl' },
  { label: 'm5.12xlarge', type: 'm512xl' },
  { label: 'm5.16xlarge', type: 'm516xl' },
  { label: 'm5.24xlarge', type: 'm524xl' }];

  constructor(private calculatorService: CalculatorService, private dialog: MatDialog, private fb: FormBuilder) {

    this.calculatorService.registerListener(this);

    this.calculatorModel = this.calculatorService.getCalculatorModel();

    this.estimates = this.calculatorService.getEstimates();

    this.sqleConsumptionRate = this.estimates.sqleConsumption;
    this.mleConsumptionRate = this.estimates.mleEconsumption;


    this.resetCustomConfigForm();

    this.sqleConfigForm.controls['sqleNode'].setValue(this.calculatorModel.sqleNode);
    this.sqleConfigForm.controls['sqleNodeCount'].setValue(this.calculatorModel.sqleNodeCount);

    this.mleConfigForm.controls['mleNode'].setValue(this.calculatorModel.mleNode);
    this.mleConfigForm.controls['mleNodeCount'].setValue(this.calculatorModel.mleNodeCount);

    this.onInputChange();
    this.onTierChanged(false);
  }

  resetCustomConfigForm() {
    this.sqleConfigForm = this.fb.group({
      sqleNodeCount: [undefined, [Validators.max(64), Validators.min(2)]],
      sqleNode: []
    });
    this.mleConfigForm = this.fb.group({
      mleNodeCount: [undefined, [Validators.max(10), Validators.min(1)]],
      mleNode: ['m524xlmle']
    });
  }

  ngOnInit() {
    this.mobilecheck();
  }

  mobilecheck = function () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  };

  onEstimatesChanged(estimates: Estimates) {
    this.estimates = estimates;
    this.sqleConsumptionRate = estimates.sqleConsumption;
    this.mleConsumptionRate = estimates.mleEconsumption;
  }

  showOrHideDefaultEntitledHours() {
    if (this.calculatorModel.configOption == 'newSQL') {
      if (this.calculatorModel.term && this.calculatorModel.sqleNode
        && this.calculatorModel.sqleNodeCount && this.calculatorModel.sqleNodeCount !== 0
      ) {
        this.defaultTCoreHoursState = 'visible';
      } else {
        this.defaultTCoreHoursState = 'hidden';
      }
    } else {
      if (this.calculatorModel.term && this.calculatorModel.mleNode
        && this.calculatorModel.mleNodeCount && this.calculatorModel.mleNodeCount !== 0
        && this.calculatorModel.sqleNode
        && this.calculatorModel.sqleNodeCount && this.calculatorModel.sqleNodeCount !== 0
      ) {
        this.defaultTCoreHoursState = 'visible';
      } else {
        this.defaultTCoreHoursState = 'hidden';
      }
    }

  }

  onInputChange() {
    this.showOrHideDefaultEntitledHours();
    this.calculateEstimates();
  }

  onTierChanged(calledByTogglingTierRadios) {
    if (this.calculatorModel.tier) {
      this.termMessageState = 'visible';
      this.tierSelected = true;
    } else {
      this.termMessageState = 'hidden';
      this.tierSelected = false;
    }
    if (calledByTogglingTierRadios) {
      this.onSQLConfigChanged();
      this.onMleConfigChanged();
    }
    this.onInputChange();
  }
  onSQLConfigChanged() {
    if (this.sqleConfigForm.valid) {
      this.calculatorModel.sqleNodeCount = this.sqleConfigForm.controls['sqleNodeCount'].value;
      this.calculatorModel.sqleNode = this.sqleConfigForm.controls['sqleNode'].value;
    } else {
      this.calculatorModel.sqleNode = this.sqleConfigForm.controls['sqleNode'].value;
      this.calculatorModel.sqleNodeCount = 0;
    }
  }

  onMleConfigChanged() {
    if (this.mleConfigForm.valid) {
      this.calculatorModel.mleNodeCount = this.mleConfigForm.controls['mleNodeCount'].value;
      this.calculatorModel.mleNode = this.mleConfigForm.controls['mleNode'].value;
    } else {
      this.calculatorModel.mleNode = this.mleConfigForm.controls['mleNode'].value;
      this.calculatorModel.mleNodeCount = 0;
    }

  }

  onConfigOptionChanged() {
    if (this.calculatorModel.configOption === 'newSQL') {
      this.mleConfigForm.controls['mleNodeCount'].setValue(undefined);
      this.mleConfigForm.controls['mleNode'].setValue('m524xlmle');
      this.selectedMleNode = 'm524xlmle';
      this.calculatorModel.mleNode = undefined;
      this.calculatorModel.mleNodeCount = undefined;
      this.onSQLConfigChanged();
    } else {
      this.onSQLConfigChanged();
      this.onMleConfigChanged();
    }
    this.onInputChange();
  }

  openRegionsHelp() {
    this.dialog.open(this.regionsHelp);
  }

  openScaleOutHelp() {
    this.dialog.open(this.scaleOutHelp);
  }

  openScaleUpHelp() {
    this.dialog.open(this.scaleUpHelp);
  }

  openScaleDownHelp() {
    this.dialog.open(this.scaleDownHelp);
  }

  openStopHelp() {
    this.dialog.open(this.stopHelp);
  }

  openBaselineConfigHelp() {
    this.dialog.open(this.baselineConfigHelpModal);
  }

  openBaselineTCoresHelp() {
    this.dialog.open(this.baselineTCoresHelp);
  }
  openTierHelp() {
    this.dialog.open(this.tierHelpModel);
  }
  calculateEstimates() {
    this.calculatorService.calculateBaselineEstimates();
  }

  getInstanceDescription() {
    let label = '';
    switch (this.calculatorModel.sqleNode) {
      case 'm54xl':
        label = 'm5.4xlarge'
        break;
      case 'm58xl':
        label = 'm5.8xlarge';
        break;
      case 'm512xl':
        label = 'm5.12xlarge';
        break;
      case 'm516xl':
        label = 'm5.16xlarge';
        break;
      case 'm524xl':
        label = 'm5.24xlarge';
        break;
      default:
        break;
    }
    return label;
  }

  resetRequested() {
    this.defaultTCoreHoursState = 'hidden';
    this.termMessageState = 'hidden';
    this.tierSelected = false;
    this.resetCustomConfigForm();
  }
}
