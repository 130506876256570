import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ScaleAttributes } from '../../services/scale-attributes';
import { CalculatorService, EstimatesListener } from '../../services/calculator.service';
import { Estimates } from '../../services/estimates-viewModel';
import { DecimalPipe } from '@angular/common';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MaxValueDirective } from '../../directives/max-value.directive';
import { Utils } from '../../shared/utils';
import { CalculatorModel } from '../../services/calculator-viewModel';
import { ScalePossibility } from '../../services/scale-possibility';
import { MatSnackBar } from '@angular/material';
import { ExceededSnackBarComponent } from '../exceeded-snack-bar/exceeded-snack-bar.component';

function initForm(fb: FormBuilder): FormGroup {
  return fb.group({
    'days': [0, Validators.min(0)],
    'frequency': ['week', Validators.required],
    'hours': [24, [Validators.max(24), Validators.min(1)]]
  });
}

@Component({
  selector: 'app-scale-scenario',
  templateUrl: './scale-scenario.component.html',
  styleUrls: ['./scale-scenario.component.scss']
})
export class ScaleScenarioComponent implements OnInit, OnChanges, EstimatesListener {
  placeholderVisible = false;
  placeholderMessage: string;
  noneChecked = false;
  retainRequired = false;
  defaultPlaceHolderVisible = false;
  exceededClockHours = false;

  @Input() baselineConfig: string;

  @Input() term: string;

  @Input() scenario: string;

  @Input() nodeCount: number;

  @Input() tier: string;

  @Input() region: string;
  scaleUpPossibilitiesMap = {};
  scaleDownPossibilitiesMap = {};

  scaleOutPossibilitiesMap = {
    '2x': {
      multiplier: 2,
      label: 'Scale out 2x',
      checked: false,
      attributes: null,
      estimatedTCores: 0,
      form: initForm(this.fromBuilder),
      disabled: false,
      scenario: 'out',
    },
    '4x': {
      multiplier: 4,
      label: 'Scale out 4x',
      checked: false,
      attributes: null,
      estimatedTCores: 0,
      form: initForm(this.fromBuilder),
      disabled: false,
      scenario: 'out',
    }
  };

  // @ViewChild('exceededError') exceededErrorRef:ElementRef; 

  scalePossibilities: ScalePossibility[] = [];

  constructMapForScalePossibility(key, sourceLabel = null,
    targerLabel = null, scenario = null, sourceInstanceType = null, targetInstanceType = null, fb = null) {
    if (!(key in this)) {
      this[key] = [];
    }
    if (sourceLabel && targerLabel && scenario && sourceInstanceType && targetInstanceType)
      this[key].push({
        source: sourceLabel,
        target: targerLabel,
        checked: false,
        attributes: new ScaleAttributes(sourceInstanceType, targetInstanceType, null, null),
        estimatedTCores: 0,
        scenario: scenario,
        form: fb.group({
          'days': [0, Validators.min(0)],
          'frequency': ['week', Validators.required],
          'hours': [24, [Validators.max(24), Validators.min(1)]]
        })
      })
  }


  initPossibilityMap() {
    //construct up scenario map for m54xl
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm54xl', 'm5.4xlarge', 'm5.8xlarge', 'up', 'm54xl', 'm58xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm54xl', 'm5.4xlarge', 'm5.12xlarge', 'up', 'm54xl', 'm512xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm54xl', 'm5.4xlarge', 'm5.16xlarge', 'up', 'm54xl', 'm516xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm54xl', 'm5.4xlarge', 'm5.24xlarge', 'up', 'm54xl', 'm524xl', this.fromBuilder);
    //construct up scenario map for m58xl
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm58xl', 'm5.8xlarge', 'm5.12xlarge', 'up', 'm58xl', 'm512xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm58xl', 'm5.8xlarge', 'm5.16xlarge', 'up', 'm58xl', 'm516xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm58xl', 'm5.8xlarge', 'm5.24xlarge', 'up', 'm58xl', 'm524xl', this.fromBuilder);
    //construct up scenario map for m512xl
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm512xl', 'm5.12xlarge', 'm5.16xlarge', 'up', 'm512xl', 'm516xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm512xl', 'm5.12xlarge', 'm5.24xlarge', 'up', 'm512xl', 'm524xl', this.fromBuilder);
    //construct up scenario map for m512xl
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm516xl', 'm5.16xlarge', 'm5.24xlarge', 'up', 'm516xl', 'm524xl', this.fromBuilder);
    //construct up scenario map for m524xl
    this.constructMapForScalePossibility.call(this.scaleUpPossibilitiesMap, 'm524xl');
    //construct down scenario map for m54xl
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm524xl', 'm5.24xlarge', 'm5.16xlarge', 'down', 'm524xl', 'm516xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm524xl', 'm5.24xlarge', 'm5.12xlarge', 'down', 'm524xl', 'm512xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm524xl', 'm5.24xlarge', 'm5.8xlarge', 'down', 'm524xl', 'm58xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm524xl', 'm5.24xlarge', 'm5.4xlarge', 'down', 'm524xl', 'm54xl', this.fromBuilder);
    //construct up scenario map for m58xl
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm516xl', 'm5.16xlarge', 'm5.12xlarge', 'down', 'm516xl', 'm512xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm516xl', 'm5.16xlarge', 'm5.8xlarge', 'down', 'm516xl', 'm58xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm516xl', 'm5.16xlarge', 'm5.4xlarge', 'down', 'm516xl', 'm54xl', this.fromBuilder);
    //construct up scenario map for m512xl
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm512xl', 'm5.12xlarge', 'm5.8xlarge', 'down', 'm512xl', 'm58xl', this.fromBuilder);
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm512xl', 'm5.12xlarge', 'm5.4xlarge', 'down', 'm512xl', 'm54xl', this.fromBuilder);
    //construct up scenario map for m512xl
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm58xl', 'm5.8xlarge', 'm5.4xlarge', 'down', 'm58xl', 'm54xl', this.fromBuilder);
    //construct up scenario map for m524xl
    this.constructMapForScalePossibility.call(this.scaleDownPossibilitiesMap, 'm54xl');
  }


  constructor(private calculatorService: CalculatorService,
    private fromBuilder: FormBuilder, private snackBar: MatSnackBar) {
    this.calculatorService.registerListener(this);
    this.retainRequired = true;
    this.initPossibilityMap();
  }

  copyPossibilities(source: ScalePossibility, target: ScalePossibility) {
    target.checked = source.checked;
    target.estimatedTCores = source.estimatedTCores;
    target.form = source.form;
    target.attributes = source.attributes;
  }

  removePossibilityFromModel(model: CalculatorModel, possibility: ScalePossibility) {
    let indexToDrop = -1;
    model.scalePossibilities.forEach((p, index) => {
      if (p.attributes.sourceConfig === possibility.attributes.sourceConfig
        && p.attributes.targetConfig === possibility.attributes.targetConfig
        && p.multiplier === possibility.multiplier) {
        indexToDrop = index;
      }
    });
    if (indexToDrop !== -1) {
      model.scalePossibilities.splice(indexToDrop, 1);
    }
  }

  retainState(model: CalculatorModel) {
    const possibilitiesCopy = model.scalePossibilities.slice();
    possibilitiesCopy.forEach((saved, index) => {
      this.scalePossibilities.forEach(possibility => {
        if (saved.attributes.sourceConfig === possibility.attributes.sourceConfig
          && saved.attributes.targetConfig === possibility.attributes.targetConfig
          && saved.multiplier === possibility.multiplier) {
          this.copyPossibilities(saved, possibility);
          this.removePossibilityFromModel(model, possibility);
          model.scalePossibilities.push(possibility);
        }
      });
    });
    this.retainRequired = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.baselineConfig || !this.term || !this.nodeCount || !this.tier || !this.region) {
      this.placeholderMessage = 'Please select the region, license tier, and the baseline configuration';
      this.placeholderVisible = true;
    } else if (this.baselineConfig && this.term && this.region && this.tier) {
      this.placeholderVisible = false;

      for (const propName in changes) {
        if ((propName === 'term' || propName === 'nodeCount'
          || propName === 'region' || propName === 'tier') && this.isAnyPossibilityChecked()) {
          this.upadatePossibilities();
          this.triggerChangeForAllPossibilities();
        } else {
          this.changeScalePossibilities();
        }
      }
    }

    if (this.retainRequired) {
      this.retainState(this.calculatorService.getCalculatorModel());
    }
  }

  onEstimatesChanged(estimates: Estimates) { }

  private changeScalePossibilities() {
    this.resetPossibilities();

    if (this.scenario === 'UP') {
      this.defaultPlaceHolderVisible = true;
      this.placeholderMessage = 'Scale Up is only supported for the Advanced SQL Engine';
      this.scalePossibilities = this.scaleUpPossibilitiesMap[this.baselineConfig];
    } else if (this.scenario === 'DOWN') {
      this.defaultPlaceHolderVisible = true;
      this.placeholderMessage = 'Scale Down is only supported for the Advanced SQL Engine'
      this.scalePossibilities = this.scaleDownPossibilitiesMap[this.baselineConfig];
    } else if (this.scenario === 'OUT') {
      this.defaultPlaceHolderVisible = true;
      this.placeholderMessage = 'Scale Out is only supported for the Advanced SQL Engine'
      this.populateScaleOutPossibilities();
    }

    if (this.scalePossibilities.length === 0) {
      if (this.scenario === 'UP') {
        this.placeholderMessage = 'You have selected the largest baseline configuration. You cannot perform the scale up operation.';
      } else {
        this.placeholderMessage = 'You cannot perform the scale down operation as you are on the smallest baseline configuration';
      }
      this.placeholderVisible = true;
    }
    this.upadatePossibilities();
  }

  private upadatePossibilities() {
    if (this.scenario !== 'OUT') {
      this.scalePossibilities.forEach(possibility => {
        possibility.attributes.sourceNodeCount = this.nodeCount;
        possibility.attributes.targetNodeCount = this.nodeCount;
      });
    } else {
      this.scalePossibilities.forEach(possibility => {
        possibility.attributes.sourceNodeCount = this.nodeCount;
        possibility.attributes.targetNodeCount = possibility.multiplier * this.nodeCount;
        this.decideMultiplierDisableFlags(possibility);
      });
    }
  }

  private decideMultiplierDisableFlags(possibility: ScalePossibility) {
    if (possibility.multiplier === 2) {
      if (this.nodeCount > 32) {
        possibility.disabled = true;
        this.noneChecked = true;


      } else {
        possibility.disabled = false;
        this.noneChecked = false;
      }
    }

    if (possibility.multiplier === 4) {
      if (this.nodeCount > 16) {
        possibility.disabled = true;
      } else {
        possibility.disabled = false;
      }
    }

    if (possibility.disabled) {
      this.resetPossibilities();
    }
  }

  private populateScaleOutPossibilities() {
    this.scalePossibilities = [];
    Object.keys(this.scaleOutPossibilitiesMap).map(key => {
      const possibility = this.scaleOutPossibilitiesMap[key];
      possibility.attributes = new ScaleAttributes(this.baselineConfig, this.baselineConfig, null, null);
      possibility.attributes.sourceNodeCount = this.nodeCount;
      possibility.attributes.targetNodeCount = this.nodeCount * possibility.multiplier;

      this.decideMultiplierDisableFlags(possibility);
      this.scalePossibilities.push(possibility);
    });
  }

  ngOnInit() {
    this.calculatorService.onTotalClockHoursUpdate.subscribe(() => {
      this.exceededClockHours = false;
      this.openSnackBar();
      this.calculatorService.scalePosssibilities.forEach((possibility) => {
        this.calculateTCoresForScaleAttricutes(possibility);
      })
    })
  }

  private isAnyPossibilityChecked() {
    return this.scalePossibilities.filter(p => p.checked).length > 0;
  }

  private triggerChangeForAllPossibilities() {
    this.scalePossibilities.filter(p => p.checked).forEach(possibility => {
      this.possibilityChanged(possibility);
    });
  }

  daysValid(possibility) {
    return Utils.daysValid(possibility.form);
  }

  private formValid(form: FormGroup) {
    return (Utils.daysValid(form)
      && form.controls['hours'].valid
      && form.controls['frequency'].valid);
  }

  private copyFormFieldToModel(possibility: ScalePossibility) {
    possibility.attributes.days = possibility.form.controls['days'].value;
    possibility.attributes.frequency = possibility.form.controls['frequency'].value;
    possibility.attributes.hours = possibility.form.controls['hours'].value;
  }

  private calculateTCoresForScaleAttricutes(possibility) {
    possibility.estimatedTCores =
      this.calculatorService.getTCoreHoursForScaleAttributes(possibility.attributes);
    this.calculatorService.reEstimateUsingModifiedScaleAttributes();
  }

  possibilityChanged(possibility: ScalePossibility) {
    this.copyFormFieldToModel(possibility);

    if (!this.formValid(possibility.form)) {
      possibility.attributes.days = 0;
    }
    this.calculatorService.removeScalePossibilityToEstimates(possibility);
    this.calculatorService.addScalePossibilityToEstimates(possibility);
    if (this.calculatorService.isSelectedHoursExceeded(null)) {
      this.exceededClockHours = true;
      this.openSnackBar();

    } else {
      this.exceededClockHours = false;
      this.openSnackBar();
      this.calculateTCoresForScaleAttricutes(possibility);
    }
  }

  maxValueForDays(possibility) {
    if (possibility.form.controls['frequency'].value === 'week') {
      return 7;
    } else if (possibility.form.controls['frequency'].value === 'month') {
      return 30;
    } else if (possibility.form.controls['frequency'].value === 'year') {
      return 365;
    }
  }

  onNoneChanged() {
    if (this.noneChecked) {
      this.resetPossibilities();
    }
  }

  openSnackBar() {
    if (this.exceededClockHours) {
      if (!this.snackBar._openedSnackBarRef)
        this.snackBar.openFromComponent(ExceededSnackBarComponent);
    } else {
      this.calculatorService.notifySnackBarDismiss.next();
    }
  }


  uncheckNoneOnSelected(possibility: ScalePossibility) {
    this.exceededClockHours = this.calculatorService.isSelectedHoursExceeded(null) ? true : false;
    this.openSnackBar();
    if (possibility.checked) {
      this.noneChecked = false;
    } else {
      this.resetPossibility(possibility);
    }
  }

  private resetPossibilities() {
    this.scalePossibilities.forEach(possibility => {
      this.resetPossibility(possibility);
    });

  }

  private resetPossibility(possibility: ScalePossibility) {
    this.calculatorService.removeScalePossibilityToEstimates(possibility);
    possibility.checked = false;
    possibility.attributes.reset();
    possibility.estimatedTCores = 0;

    possibility.form.controls['days'].setValue(0);
    possibility.form.controls['hours'].setValue(24);
    possibility.form.controls['frequency'].setValue('week');
    this.exceededClockHours = this.calculatorService.isSelectedHoursExceeded(null) ? true : false;
    this.openSnackBar();
  }

  getEstimatedTCoreHoursForScaleScenario(possibility: ScalePossibility): string {
    if (possibility.estimatedTCores === 0) {
      if (possibility['scenario'] == 'down') {
        this.calculatorService.isDownSelected = false;
      }
      return '0';
    } else {
      let tCoreHours = possibility.estimatedTCores;
      if (possibility.estimatedTCores < 0) {
        tCoreHours = -1 * possibility.estimatedTCores;
      }

      if (possibility['scenario'] == 'down') {
        this.calculatorService.isDownSelected = true;
      }
      return new DecimalPipe('en-US').transform(tCoreHours);
    }
  }

  resetRequested() {
    this.resetPossibilities();
  }

}
